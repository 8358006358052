import AccountService from '@/services/account.service.js'
import UploadLogo from '../UploadLogo/UploadLogo.vue'
import EventBus from '@/util/EventBus'

export default {
  name: 'ProfileForm',
  components: { UploadLogo },
  props: {
    profile: {
      type: Object,
      require: true,
    },
  },
  data () {
    return {
      loading: false,
      loadingLogo: false,
      showToken: false,
      cropper: false,
    }
  },
  methods: {
    append () {
      this.showToken = !this.showToken
    },
    async deleteLogo () {
      this.loadingLogo = true

      try {
        await this.$store.dispatch('logo/deleteLogo')
        EventBus.$emit('showAlert', 'success', this.$t('Se ha borrado la imagen'))
        this.profile.image = this.$store.state.logo.img
      } catch (error) {
        if (error.response.status === 400) {
          EventBus.$emit('showAlert', 'danger', this.$errorMessages.getMessage(error.response.data.code))
        } else {
          EventBus.$emit('showAlert', 'danger', this.$t('Error al intentar eliminar la imagen'))
        }
      } finally {
        this.loadingLogo = false
      }
    },
    updateProfile () {
      this.loading = true
      const data = {
        companyInformation: this.profile.company,
        lang: this.lang,
        timezone: this.timezone,
      }
      AccountService.updateProfile(data)
        .then(
          () => {
            AccountService.successResponse(this.$t('Se ha actualizado el perfil de usuario correctamente.'))
          },
          (error) => {
            AccountService.errorResponse(
              error.response.status,
              this.$t('Error al intentar actualizar perfil del usuario'),
              this.$t(error.response.data.message),
            )
          },
        )
        .finally(() => {
          this.loading = false
        })
    },
    showImage () {
    },
    showCropper () {
      EventBus.$emit('showCropper')
    },
  },
}
