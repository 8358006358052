import { Profile } from '@/models/account/Profile.js'
import AccountService from '@/services/account.service.js'
import ProfileMenu from '../Common/ProfileMenu/ProfileMenu.vue'
import SupportCard from '../Common/SupportCard/SupportCard.vue'
import ProfileForm from './ProfileForm/ProfileForm.vue'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'

export default {
  name: 'Profile',
  components: {
    ProfileMenu,
    SupportCard,
    ProfileForm,
    HeaderTopDashboard,
  },
  data () {
    return {
      profile: new Profile(),
    }
  },
  created () {
    this.getProfile()
  },
  methods: {
    getProfile () {
      AccountService.getProfile().then(
        (response) => {
          this.profile = new Profile(
            response.activeTimezone,
            response.company,
            response.countriesMccList,
            response.language,
            response.languages,
            response.user,
            response.apiKey,
            response.timezoneOffsets,
            response.image,
            true,
          )
          this.getLogo()
        },
        (error) => {
          AccountService.errorResponse(
            error.response.status,
            this.$t('Error al intentar ver perfil del usuario'),
            this.$t(error.response.data.message),
          )
        },
      )
      .finally(() => {})
    },
    async getLogo () {
      try {
        await this.$store.dispatch('logo/getLogo')
        this.profile.image = this.$store.state.logo.img
      } catch (error) {
        console.error(error)
      } finally {}
    },
  },
}
