import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import EventBus from '@/util/EventBus'

export default {
  name: 'UploadLogo',
  props: {
    showCropper: {
      type: Boolean,
      required: true,
    },
    profile: {
      type: Object,
      require: true,
    },
  },
  components: {
    VueCropper,
  },
  data () {
    return {
      imgSrc: require('@/assets/img/images/default-avatar.svg'),
      data: null,
      show: false,
      loading: false,
      file: '',
      ready: true,
    }
  },
  mounted () {
    const that = this
    EventBus.$on('showCropper', function () {
      that.show = true
    })

    EventBus.$on('setDefaultAvatar', () => {
      this.reset()
    })
  },
  methods: {
    reset () {
      this.$refs.input.value = null
      this.imgSrc = require('@/assets/img/images/default-avatar.svg')
      this.$refs.cropper.replace(require('@/assets/img/images/default-avatar.svg'))
    },
    setImage (e) {
      if (e.target.files[0]) {
        this.file = e.target.files[0]
        if (this.file.type.indexOf('image/') === -1) {
          alert('Please select an image file')
          return
        }
        if (typeof FileReader === 'function') {
          const reader = new FileReader()
          reader.onload = (event) => {
            this.imgSrc = event.target.result
            // rebuild cropperjs with the updated source
            this.$refs.cropper.replace(event.target.result)
          }
          reader.readAsDataURL(this.file)
        } else {
          alert('Sorry, FileReader API not supported')
        }
      }
    },
    showFileChooser () {
      this.$refs.input.click()
    },
    async uploadImage () {
      const fileData = this.$refs.cropper.getData()
      const data = new FormData()
      data.append('x', fileData.x)
      data.append('y', fileData.y)
      data.append('w', fileData.width)
      data.append('h', fileData.height)
      data.append('logo', this.file)
      this.show = true
      this.loading = true

      try {
        await this.$store.dispatch('logo/uploadLogo', data)
        EventBus.$emit('showAlert', 'success', this.$t('Se ha cargado la imágen'))
        this.profile.image = this.$store.state.logo.img
      } catch (error) {
        if (error.response.status === 400) {
          EventBus.$emit('showAlert', 'danger', this.$errorMessages.getMessage(error.response.data.code))
        } else {
          EventBus.$emit('showAlert', 'danger', this.$t('Error al subir la imagen'))
        }
      } finally {
        this.loading = false
        this.show = false
      }
    },
  },
}
