export class Profile {
  activeTimezone
  company
  countriesMccList
  language
  languages
  user
  timezoneOffsets
  image
  show

  constructor (
    activeTimezone = null,
    company = {},
    countriesMccList = [],
    language = false,
    languages = [],
    user = {},
    apiKey = null,
    timezoneOffsets = [],
    image = null,
    show = false,
  ) {
    this.activeTimezone = activeTimezone
    this.company = company
    this.company.country_id = String(company.country_id)
    this.countriesMccList = countriesMccList
    this.language = language
    this.languages = languages
    this.user = user
    this.apiKey = apiKey
    this.timezoneOffsets = timezoneOffsets
    this.image = image
    this.show = show
  }
}
